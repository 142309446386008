import React from "react"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import { useSiteMetadata } from "../utils/hooks/use-site-metadata"
import { Script } from "gatsby"

const SeoComponent = ({ title, description, image, article, datePublished, dateModified, videoURL, recaptcha, children }) => {
    const { pathname } = useLocation()
    const schemaMarkup = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Visby Medical",
        "url": "https://www.visbymedical.com/",
        "logo": "https://media.visbymedical.com/VisbyLogoTM.png",
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "833-468-4729",
            "contactType": "customer service",
            "contactOption": "TollFree",
            "areaServed": "US",
            "availableLanguage": "en"
        },
        "sameAs": [
            "https://www.linkedin.com/company/visbymedical/",
            "https://twitter.com/visbymedical",
            "https://www.facebook.com/visbymedical/"
        ]
    }
    const {
        defaultTitle,
        defaultDescription,
        siteUrl,
        defaultImage,
        twitterUsername,
    } = useSiteMetadata()

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname}`,
    }
    return (
        <>
            <title>{seo.title + " | " + defaultTitle}</title>
            <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Visby Medical" />
            <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
            <script src="/stack-adapt-pixel.js" />
            <meta property="og:locale" content="en_US" />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            { recaptcha && <Script src="https://www.google.com/recaptcha/api.js?render=explicit" async /> }
            { article && <meta property="og:type" content="article" />}
            {seo.title && <meta property="og:title" content={seo.title + " | " + defaultTitle} />}
            {seo.description && (
                <meta property="og:description" content={seo.description} />
            )}
            <meta property="og:image" content={seo.image} />
            <meta name="og:image:alt" content={seo.title} />
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && (
                <meta name="twitter:creator" content={twitterUsername} />
            )}
            {twitterUsername && (
                <meta name="twitter:site" content={twitterUsername} />
            )}
            {datePublished && <meta name="datePublished" content={datePublished} />}
            {datePublished && <meta name="publish_date" property="og:publish_date" content={datePublished} />}
            {dateModified && <meta name="dateModified" content={dateModified} />}
            {videoURL && <meta name="og:video" content={videoURL} />}
            {videoURL && <meta property="og:video:url" content={videoURL} />}
            {children}
        </>
    )
}

export default SeoComponent

SeoComponent.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    datePublished: PropTypes.string,
    dateModified: PropTypes.string,
    videoURL: PropTypes.string,
    article: PropTypes.bool,
    recaptcha: PropTypes.bool,
    children: PropTypes.node
}
SeoComponent.defaultProps = {
    title: null,
    description: null,
    image: null,
    datePublished: null,
    dateModified: null,
    videoURL: null,
    article: false,
    recaptcha: false,
    children: null
}

