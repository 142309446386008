import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import HubspotForm from "components/hubspot"

const StyledForm = styled.section`
  padding: 68px 0;


  ${breakpoint.medium`
    padding: 104px 0;
  `}
  .msg-confirm {
    margin: 10px;
  }

  .title {
    margin-bottom: 40px;
    text-align: center;

    ${breakpoint.medium`
      margin-bottom: 60px;
    `}
  }
  
    h2 {
      padding-bottom: 1rem;
    }

    a {
      color: ${colors.orange};

      &:hover {
        color: ${colors.purpleDark};
      }
    }
  }


`

const ContactForm = props => {
  
  return (
    <StyledForm>
      <Container data-aos="fade-up">
        <div className="partner__form">
        <HubspotForm
            formId='26246839-ad89-48d3-80a9-19dff19ee284'
          />
        </div>
      </Container>
    </StyledForm>
  )
}

class FullForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      enableBothFields: false
    }
  }


	enableFields = () => {
    this.setState({
      enableBothFields: true,
    },
        () => {
          document.getElementById('zip').disabled = false
          document.getElementById('state').disabled = false
          document.getElementById('zipField').classList.add("show")
          document.getElementById('stateField').classList.add("show")
        })
	}

	disableFields = () => {
    this.setState({
      enableBothFields: false,
    },
        () => {
          document.getElementById('zip').disabled = true
          document.getElementById('state').disabled = true
          document.getElementById('zipField').classList.remove("show")
          document.getElementById('stateField').classList.remove("show")
        })
	}
	retrieveState = () =>{
		var zipCode = document.getElementById('zip');
		var stateDropDown = document.getElementById('state');
		var zip = zipCode.value;
		if(zip==="") {
			stateDropDown.value="";
			} else {
			var url = 'http://api.zippopotam.us/us/' + zip;
			var xhr = new XMLHttpRequest();

			xhr.onreadystatechange = function () {
				if (xhr.readyState === 4) {
					var result = xhr.responseText;
					var zippo = JSON.parse(result);
					if(zippo.places) {
						stateDropDown.value=zippo.places[0]['state abbreviation'];
					} else {
						stateDropDown.value="";
					}
				}
			};
			xhr.open('GET', url, true);
			xhr.send(null);
		}
	}


  render = () => (
    <ContactForm
    enableFields={this.enableFields}
		disableFields={this.disableFields}
    retrieveState={this.retrieveState}
   />
  )
}

export default FullForm
