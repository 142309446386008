import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import Hero from "./screens/how-to-buy/hero"
import Form from "./screens/how-to-buy/form"

const HowToBuy = () => {
  return (
    <React.Fragment>
      <Hero />
      <Form />
    </React.Fragment>
  )
}
export default HowToBuy

export const Head = () => (
  <Seo
		title="How to Buy"
		description="Request a product demo or more info here to see how Visby could fit into your point of care setting."
		image="/meta/how-to-buy.jpg"
    	recaptcha
  />
)
