import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledHero = styled.section`
	background-color: ${colors.purpleDark};
	color: ${colors.white};
	text-align: center;

  ${Container} {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 300px;

    ${breakpoint.medium`
        max-width: 900px;
        height: 320px;
    `}
}

  h1 { 
    color: ${colors.white}; 
    padding-bottom: 0;

    ${breakpoint.medium`
      padding-bottom: 1rem;
    `}
  }

  p {
    margin-bottom: 0.8rem;
    line-height: 1.3em;

      &.bold {
  		font-weight: 600!important;
 	   }

     &.smaller {
      font-size: 14px;
     }

  ${breakpoint.medium`
      line-height: 1.6em;

      &.smaller {
        font-size: 16px;
       }

  `}


  }

  a {
    color: ${colors.orange};

    &:hover {
      color: ${colors.charcoal};
    }
  }	

`

const Hero = ({ pageTitle, children }) => (
  <StyledHero data-aos="fade-in">
    <Container>
      <h1>{pageTitle}</h1>
      {children}
    </Container>
  </StyledHero>
)

export default Hero
