import React from "react"

// Components
import Hero from "../../../components/hero"

const HowToBuyHero = () => {
  return (
    <Hero pageTitle="Request a demo or more info">
      <p>
        Please complete the form below and we will get in touch with you shortly.
      </p>
      <p className="smaller">For Professional Use Only. Visby products are not FDA-cleared for personal “at home” use.</p>
    </Hero>
  )
}
export default HowToBuyHero